<template>
  <div class="legal">
    <h1 class="mb-5"><strong>Cookies Policy</strong></h1>
    <p>
      NB: &lsquo;We Are Locals&rsquo; and &lsquo;We Are BS3&rsquo; are both
      trading names of Bristol Payji.
    </p>
    <p>Last Updated: March 24th, 2020</p>
    <p>
      <em
        >By using or accessing the &lsquo;'wearelocal' &amp; 'wearebs3'&rsquo;
        websites (&ldquo;Site&rdquo;), you agree to &lsquo;'wearelocal' &amp;
        'wearebs3'&rsquo; trading as Bristol Payji&rsquo;s use of Cookies as
        outlined below. All references to Businesses are defined as provided for
        in the &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; Privacy Policy.</em
      >
    </p>
    <p>
      <em
        >For the purposes of this Cookie Policy, &ldquo;'wearelocals' &amp;
        'wearebs3'&rdquo; or &ldquo;we&rdquo; means Bristol Payji.</em
      >
    </p>
    <h2><strong>Introduction:</strong></h2>
    <p>
      This Cookie policy helps to explain the occasions when and why cookies may
      be sent to visitors to the Site (referred to in this policy as
      &ldquo;'wearelocals' &amp; 'wearebs3'&rdquo;, &ldquo;we&rdquo;,
      &ldquo;us&rdquo; or &ldquo;our&rdquo;).
    </p>
    <h2><strong>1. What is a cookie?</strong></h2>
    <p>
      An HTTP cookie (also called web cookie, Internet cookie, browser cookie,
      or simply cookie) is a small piece of data sent from a website and stored
      on the user's computer by the user's web browser while the user is
      browsing. Cookies were designed to be a reliable mechanism for websites to
      remember stateful information (such as items added in the shopping cart in
      an online store) or to record the user's browsing activity (including
      clicking particular buttons, logging in, or recording which pages were
      visited in the past). They can also be used to remember arbitrary pieces
      of information that the user previously entered into form fields such as
      names, addresses, passwords, and credit card numbers.
    </p>
    <p>
      Session Cookies are temporary cookies that remain in the cookie file of
      your browser until you leave the Site.
    </p>
    <p>
      Other kinds of cookies perform essential functions in the modern web.
      Perhaps most importantly, authentication cookies are the most common
      method used by web servers to know whether the user is logged in or not,
      and which account they are logged in with. Without such a mechanism, the
      site would not know whether to send a page containing sensitive
      information, or require the user to authenticate themselves by logging in.
      With this in mind we may also employ cookies so that we remember your
      computer when it is used to return to the Site to help customize your
      'wearelocals' &amp; 'wearebs3' web experience. The security of an
      authentication cookie generally depends on the security of the issuing
      website and the user's web browser, and on whether the cookie data is
      encrypted.
    </p>
    <p>
      European law requires that all websites targeting European Union member
      states gain "informed consent" from users before storing non-essential
      cookies on their device.
    </p>
    <h2>
      <strong
        >2. How &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; uses
        cookies</strong
      >
    </h2>
    <p>
      We use cookies, web beacons, and other storage technologies from
      third-party partners such as Google for measurement services, better
      targeting ads, and for marketing purposes. This takes place from the Site
      and when you download, install or access an Bristol Payji App or web
      application such as our Talent Management System.
    </p>
    <p>
      These cookies, web beacons, and other storage technologies allow us to
      display &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; promotional material
      to you on other sites you visit across the Internet. 'wearelocals' &amp;
      'wearebs3' may also share information about your behavior on the Site with
      third parties (including operators of third-party websites and/or social
      networking sites) in order to show you targeted advertisements and other
      content that has been customized for you.
    </p>
    <p>
      We also use cookies to facilitate the &lsquo;'wearelocals' &amp;
      'wearebs3'&rsquo; Targeted Ads Program. In this Program, we use cookies to
      determine which ads users have seen, and how many times users have seen a
      particular ad.
    </p>
    <h2><strong>3. Analytics Cookies</strong></h2>
    <p>
      In order to monitor how our sites are performing, we collect data about
      page visits. This information is completely anonymous &mdash; we cannot
      determine who it came from.
    </p>
    <p>Analytics information is used in reports and to improve our site.</p>
    <p>
      In terms of your preferences &amp; user experience, 'wearelocals' &amp;
      'wearebs3' uses cookies to gather certain information about users, such as
      browser type, server, language preference, and country setting, in order
      to store user preferences on our Site to make your user experience more
      consistent and convenient.
    </p>
    <p>
      For example, we may store the recent searches you have performed in a
      cookie so that we can allow you to easily repeat those searches when you
      return to our Site etc.
    </p>
    <p>
      In addition, we use cookies to maintain your logged in state when you
      visit our Site repeatedly. We may also use your IP address to determine
      your geographic location at city or postal code level in order to provide
      you with the most relevant search results.
    </p>
    <p>
      We can also use cookies to look at patterns such as number of applicants
      from each different application webpage they come from, as well as:
    </p>
    <ul>
      <li>Demographics.</li>
      <li>The numbers of visitors to individual pages.</li>
      <li>When pages were visited.</li>
      <li>How long users stay on the page.</li>
      <li>The page they came from.</li>
    </ul>
    <p>
      Some of the other cookies we can sometimes use are for security measures
      to limit certain types of cyber attacks and finally for testing and user
      performance issues.
    </p>
    <p>
      More information about analytics cookies: We use Google to provide our
      analytics data. You can read more about how Google Analytics treats your
      data at:
      <a href="https://marketingplatform.google.com/about/"
        >Google: Safeguarding your data</a
      >.
    </p>
    <p>
      You can also read Google&rsquo;s full privacy policy
      <a href="https://policies.google.com/privacy?hl=en&amp;gl=uk">HERE</a>.
    </p>
    <p>How do I control analytics cookies?</p>
    <p>
      Google provides a tool to
      <a href="https://tools.google.com/dlpage/gaoptout?hl=None"
        >opt-out of Google Analytics</a
      >. This is available for all modern browsers in the form of a browser
      plugin. Additionally, you can control how cookies are set using your
      browser settings. See below for more information.
    </p>
    <h2><strong>4. Managing cookies in your browser</strong></h2>
    <p>
      Use the options in your web browser if you do not wish to receive a cookie
      or if you wish to set your browser to notify you when you receive a
      cookie. You can easily delete and manage any cookies that have been
      installed in the cookie folder of your browser by following the
      instructions provided by your particular browser manufacturer.
    </p>
    <ul>
      <li>
        <a href="https://support.google.com/chrome/answer/95647?hl=en-GB"
          >Google Chrome</a
        >
      </li>
      <li>
        <a href="https://support.microsoft.com/en-us/kb/260971"
          >Internet Explorer</a
        >
      </li>
      <li>
        <a
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
          >Mozilla Firefox</a
        >
      </li>
      <li>
        <a
          href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          >Safari (Desktop)</a
        >
      </li>
      <li>
        <a href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a>
      </li>
      <li>
        <a
          href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&amp;answer=2425067"
          >Android Browser</a
        >
      </li>
      <li><a href="http://www.opera.com/help">Opera</a></li>
      <li>
        <a href="https://www.opera.com/help/tutorials/security/privacy/"
          >Opera Mobile</a
        >
      </li>
    </ul>
    <p>
      If your browser is not listed here, consult the documentation that your
      particular browser manufacturer provides. You may also consult your mobile
      device documentation for information on how to disable cookies on your
      mobile device. If you disable all cookies, you may not be able to take
      advantage of all the features of this Site. Please note that if you have
      not cleared your cookies or cache, the contents of which may affect
      autofill functions on the &lsquo;A Hob In Hospitality&rsquo; site and you
      are responsible for any such actions.
    </p>
    <h2><strong>5. Changes to our cookie policy</strong></h2>
    <p>
      Please review the &ldquo;Last Updated&rdquo; date at the top of the Cookie
      Policy to determine when it was last amended. Any changes will become
      effective on the &ldquo;Last Updated&rdquo; date indicated above. By using
      the Site or providing information to us following such changes, you will
      have accepted the amended Cookie Policy. If &lsquo;'wearelocals' &amp;
      'wearebs3' is going to use Personal Data collected through the Site in a
      manner materially different from that stated at the time of collection,
      then Bristol Payji trading as &ldquo;'wearelocals' &amp; 'wearebs3'&rsquo;
      will notify users via email and/or by posting a notice on
      &lsquo;'wearelocals' &amp; 'wearebs3'&rsquo; Site for 30 days prior to
      such use or by other means as required by law.
    </p>
    <p>
      If you still have any concerns relating to our use of cookies, please
      contact us at gdpr@bristolpayji.com
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
