<template>
  <v-app-bar
    id="navigation"
    :class="active_full_nav ? 'white' : 'nav-darken'"
    elevate-on-scroll
    fixed
    height="80px"
  >
    <v-container class="content">
      <div class="nav" :class="active_full_nav ? 'black--text' : 'white--text'">
        <div class="logo">
          <router-link @click.native="scrollToTop" to="/">
            <v-img
              contain
              width="240px"
              to="/"
              alt="We Are Locals logo"
              :src="
                active_full_nav
                  ? require('@/assets/wearelocals_logo_small.png')
                  : require('@/assets/wearelocals_logo_white_small.png')
              "
            />
          </router-link>
        </div>
        <ul v-if="$vuetify.breakpoint.mdAndUp" class="nav-links">
          <li>
            <router-link @click.native="scrollToTop" to="/">Home</router-link>
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/benefits"
              >Benefits</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/about"
              >About</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/discover"
              >Discover</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/get-started"
              >Get Started</router-link
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://wearelocalsresourceguide.carrd.co/"
              to="/contact-us"
            >
              Support
            </a>
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/contact-us"
              >Contact us</router-link
            >
          </li>
        </ul>
        <v-btn @click="open_navigation()" icon v-else>
          <v-icon>mdi-menu</v-icon></v-btn
        >
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Navigation",
  data() {
    return {
      full_nav: this.$route.path == "/" ? false : true,
    };
  },
  computed: {
    ...mapState("AppStore", ["nav_expanded"]),
    active_full_nav() {
      if (this.$route.path === "/" && this.$vuetify.breakpoint.mdAndUp) {
        return this.full_nav;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions("AppStore", ["set_navigation_state"]),
    open_navigation() {
      this.set_navigation_state({ expanded: !this.nav_expanded });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    navigation_scroll() {
      let scroll_position = window.scrollY;
      if (scroll_position > 50) {
        this.full_nav = true;
      } else {
        this.full_nav = false;
      }

      // Any code to be executed when the window is scrolled
    },
  },
  created() {
    window.addEventListener("scroll", this.navigation_scroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.navigation_scroll);
  },
};
</script>

<style lang="scss" scoped>
.wearelocals-link {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-weight: 800;
  text-decoration: none !important;
  text-transform: uppercase;
}
.nav {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: inherit;
  }

  .nav-links {
    display: flex;
    font-weight: bold;

    li {
      margin-left: 1.4rem;
      list-style-type: none;
    }
  }
}

.nav-darken {
  background-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.3) !important;
}
</style>
