<template>
  <v-navigation-drawer :value="nav_expanded" app @input="set_nav_state">
    <div class="logo my-10 px-6">
      <router-link @click.native="scrollToTop" to="/">
        <v-img
          max-width="100%"
          to="/"
          alt="We Are Locals logo"
          :src="require('@/assets/wearelocals_logo_small.png')"
        />
      </router-link>
    </div>

    <v-divider></v-divider>

    <v-list>
      <v-list-item @click.native="scrollToTop" to="/">
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.native="scrollToTop" to="/benefits">
        <v-list-item-content>
          <v-list-item-title>Benefits</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.native="scrollToTop" to="/about">
        <v-list-item-content>
          <v-list-item-title>About</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.native="scrollToTop" to="/discover">
        <v-list-item-content>
          <v-list-item-title>Discover</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.native="scrollToTop" to="/get-started">
        <v-list-item-content>
          <v-list-item-title>Get Started</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        @click.native="scrollToTop"
        href="http://support.wearelocals.co.uk"
        target="_blank"
      >
        <v-list-item-content>
          Support
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.native="scrollToTop" to="/contact-us">
        <v-list-item-content>
          <v-list-item-title>Contact us</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Navigation",
  computed: {
    ...mapState("AppStore", ["nav_expanded"]),
  },
  methods: {
    ...mapActions("AppStore", ["set_navigation_state"]),
    set_nav_state(expanded) {
      if (this.nav_expanded !== expanded) {
        this.$store.dispatch("AppStore/set_navigation_state", { expanded });
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    this.set_navigation_state({ expanded: false });
  },
};
</script>

<style lang="scss" scoped>
.logo {
  text-align: center;
}
a {
  color: #000000 !important;
  font-weight: bold;
}
</style>
