<template>
  <div>
    <Navigation />
    <MobileNavigation v-if="$vuetify.breakpoint.smAndDown" />
    <v-content :class="$route.path !== '/' ? 'content-padding' : ''">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
    </v-content>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Main",
  components: {
    Navigation,
    Footer,
    MobileNavigation,
  },
  data() {
    return {
      prevHeight: 0,
    };
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-property: height, opacity;
  transition-duration: 0.25s;
  overflow: hidden;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
